import React, { Component } from 'react';
import './Background.css';
import { Particle } from './particle.js';  // Import the Particle class

// Define a set of colors to use for particles
const COLORS = [
    {r: 20, g: 232, b: 30},         // Electric Green
    {r: 0, g: 234, b: 141},         // Teal
    {r: 1, g: 126, b: 213},         // Blue
    {r: 32, g: 18, b: 79},          // Deep Blue
    {r: 56, g: 168, b: 61},         // Deep Green
    {r: 114, g: 71, b: 161},        // Light Purple
    {r: 50, g: 10, b: 88},          // Purple
]

class Background extends Component {
    constructor(props) {
        super(props);

        // Create a canvas element and add it to the body of the document
        this.canvas = document.createElement('canvas');
        document.body.appendChild(this.canvas);
        // Get the 2D rendering context of the canvas
        this.ctx = this.canvas.getContext('2d');

        // Check if the device has a high pixel ratio (for high-DPI displays)
        this.pixelRatio = (window.devicePixelRatio > 1) ? 2 : 1;

        // Set the number of particles and their size range
        this.totalParticles = 42;  // Number of particles
        this.particles = [];  // Array to hold particle instances
        this.maxRadius = 500;  // Maximum radius for particles
        this.minRadius = 250;  // Minimum radius for particles

        // Add an event listener to handle window resize
        window.addEventListener('resize', this.resize.bind(this), false);

        // Call the resize method to adjust canvas size
        this.resize();

        // Start the animation loop
        window.requestAnimationFrame(this.animate.bind(this));
    }

    // Adjust the canvas size and scale on window resize
    resize() {
        // Get the current width and height of the window
        this.stageWidth = document.body.clientWidth;
        this.stageHeight = document.body.clientHeight;

        // Set the canvas size considering the pixel ratio (for retina displays)
        this.canvas.width = this.stageWidth * this.pixelRatio;
        this.canvas.height = this.stageHeight * this.pixelRatio;
        // Scale the canvas for high-DPI displays
        this.ctx.scale(this.pixelRatio, this.pixelRatio);

        // Create new particles to fit the resized canvas
        this.createParticles();
    }

    // Create particles and assign them random positions and colors
    createParticles() {
        let curColor = 0;  // Track current color index
        this.particles = [];  // Reset the particle array

        // Loop to create each particle
        for (let i = 0; i < this.totalParticles; i++) {
            // Create a new Particle instance with random position and radius
            const item = new Particle(
                Math.random() * this.stageWidth,   // Random x position
                Math.random() * this.stageHeight,  // Random y position
                Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,  // Random radius between min and max
                COLORS[curColor]  // Assign a color from the COLORS array
            );

            // Cycle through the colors
            if (++curColor >= COLORS.length) {
                curColor = 0;
            }

            // Store the created particle in the particles array
            this.particles[i] = item;
        }
    }

    // Animation loop to update particle movement and rendering
    animate() {
        // Request the next animation frame
        window.requestAnimationFrame(this.animate.bind(this));

        // Clear the canvas before redrawing particles
        this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);

        // Loop through all particles and animate them
        for (let i = 0; i < this.totalParticles; i++) {
            const item = this.particles[i];
            // Call the animate method of each particle to update its position and draw it
            item.animate(this.ctx, this.stageWidth, this.stageHeight);
        }
    }

    // React's render method to return JSX
    render() {
        return (
            <div className="background">
                {/* Optional: Render a background image inside the div */}
                {/* <img className="background" alt={bgimg} src={bgimg}/> */}
            </div>
        );
    }
}

// Export the Background component as the default export
export default Background;
