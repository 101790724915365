const PI2 = Math.PI * 2;

export class Particle {
    constructor(x, y, radius, rgb) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.rgb = rgb;

        this.vx = Math.random() * 4;
        this.vy = Math.random() * 4;

        this.sinValue = Math.random();

        // Variables to control the size oscillation of the gradient
        this.initialRadius = radius;  // Store the initial radius
        this.growSpeed = Math.random() * 0.05 + 0.02; // Random speed for growing/shrinking
        this.shrinking = false;  // Toggle to track if the particle is shrinking or growing
    }

    animate(ctx, stageWidth, stageHeight) {
        // Increment the sine value to create smooth oscillation for size
        this.sinValue += 0.01;

        // Handle growing and shrinking effect
        if (this.shrinking) {
            this.radius -= this.growSpeed;  // Shrink radius
            if (this.radius <= this.initialRadius * 0.5) { // Shrink to 50% of the initial size
                this.shrinking = false; // Start growing again
            }
        } else {
            this.radius += this.growSpeed;  // Grow radius
            if (this.radius >= this.initialRadius * 1.5) { // Grow to 150% of the initial size
                this.shrinking = true; // Start shrinking again
            }
        }

        // Move the particle by adding velocity to its position
        this.x += this.vx;
        this.y += this.vy;

        // Bounce the particle off the left or right edge of the canvas
        if(this.x < 0) {
            this.vx *= -1;
            this.x += 10;
        } else if (this.x > stageWidth) {
            this.vx *= -1;
            this.x -= 10;
        }

        // Bounce the particle off the top or bottom edge of the canvas
        if(this.y < 0) {
            this.vy *= -1;
            this.y += 10;
        } else if (this.y > stageHeight) {
            this.vy *= -1;
            this.y -= 10;
        }

        // Start drawing the particle
        ctx.beginPath();

        // Create a radial gradient for the particle with the updated radius
        const g = ctx.createRadialGradient(
            this.x,
            this.y,
            this.radius * 0.01,  // Start of the gradient (almost at the center)
            this.x,
            this.y,
            this.radius           // End of the gradient (updated radius)
        );

        // Define the gradient's color stops, from opaque in the center to transparent at the edge
        g.addColorStop(0, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`);
        g.addColorStop(1, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 0)`);

        // Set the fill style to the radial gradient
        ctx.fillStyle = g;

        // Draw the particle as a filled circle (arc) at the current position with the current radius
        ctx.arc(this.x, this.y, this.radius, 0, PI2, false);
        ctx.fill();  // Fill the circle with the gradient
    }
}
